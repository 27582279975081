const de = {
    messages: {
        required: (field) => `<strong>${field}</strong> ist ein Pflichtfeld.`,
        numeric: (field) => `<strong>${field}</strong> darf nur Ziffern enthalten.`,
        email: (field) => `<strong>${field}</strong> hat kein gültiges Format.`,
        length: (field, [length]) => `<strong>${field}</strong> muss ${length}-stellig sein.`
    }
};

export default de;
