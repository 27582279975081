import Vue from 'vue';
import VueResource from 'vue-resource';
import VeeValidate from 'vee-validate';

Vue.use(VeeValidate, {
    events: 'blur'
});
Vue.use(VueResource);

const UnlockAccountForm = {
    el: '#vue-unlock-account',
    name: 'UnlockAccount',
    delimiters: ['${', '}'],

    data: {
        success: false,
        isLoading: false,
        form: {
            accountNumber: '',
            firstname: '',
            lastname: '',
            birthday: '',
            zip: '',
            idNumber: '',
            idDate: '',
            csrfToken: '',
            reason: ''
        }
    },

    mounted() {
        this.form.csrfToken = document.querySelector('.js-token').value;
        this.form.reason = document.querySelector('.js-reason').value;
    },

    methods: {
        onSubmit() {
            this.$validator.validate().then(result => {
                if (result) {
                    const formData = new FormData();

                    for (let key in this.form) {
                        if (this.form.hasOwnProperty(key)) {
                            formData.append(key, this.form[key]);
                        }
                    }

                    this.isLoading = true;

                    this.$http.post('/form/unlock-account', formData).then(() => {
                        this.success = true;
                        this.isLoading = false;
                    }, () => {
                        this.isLoading = false;
                    });
                }
            });
        }
    }
};

class UnlockAccount {
    constructor() {
        if (document.querySelector('#vue-unlock-account')) {
            this.form = new Vue(UnlockAccountForm);
        }
    }
}

export default new UnlockAccount();
