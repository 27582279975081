import Vue from 'vue';
import VueResource from 'vue-resource';
import VeeValidate from 'vee-validate';

import german from './de_DE';

Vue.use(VeeValidate, {
    events: 'blur'
});
Vue.use(VueResource);

const ContactForm = {
    el: '#vue-contact',
    name: 'ContactForm',
    delimiters: ['${', '}'],

    data: {
        success: false,
        isLoading: false,
        form: {
            accountNumber: '',
            salutation: '',
            firstname: '',
            lastname: '',
            email: '',
            phone: '+43 ',
            subject: '',
            message: '',
            csrfToken: ''
        }
    },

    mounted() {
        this.$validator.localize('de', german);
        this.form.csrfToken = document.querySelector('.js-token').value;
    },

    methods: {
        onSubmit() {
            this.$validator.validate().then(result => {
                if (result) {
                    const formData = new FormData();

                    for (let key in this.form) {
                        if (this.form.hasOwnProperty(key)) {
                            formData.append(key, this.form[key]);
                        }
                    }

                    this.isLoading = true;

                    this.$http.post('/form/contact', formData).then(() => {
                        this.success = true;
                        this.isLoading = false;
                    }, () => {
                        this.isLoading = false;
                    });
                }
            });
        }
    }
};

class Contact {
    constructor() {
        if (document.querySelector('#vue-contact')) {
            this.form = new Vue(ContactForm);
        }
    }
}

export default new Contact();
